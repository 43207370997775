import Compressor from "compressorjs";
import { navigate } from "gatsby-plugin-intl";
import moment from "moment";

import * as constants from "./app.constant";

export const numberWithCommas = (x) => {
    if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
};

export const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        cb(reader.result);
    };
    reader.onerror = (error) => {
        console.log("Error: ", error);
    };
};

export const compressImageFile = (imageFile, callBack) => {
    new Compressor(imageFile, {
        quality: 0.9,
        maxWidth: 960,
        strict: false,
        success(result) {
            callBack(result);
        },
        error(err) {
            console.log("Error: ", err.message);
        },
    });
};

export const sanitizeError = (e) => {
    let error = e;

    if (typeof e === "object") {
        if (!!e.response) {
            if (!!e.response.data) {
                error = e.response.data;
            } else {
                error = e.response;
            }
        } else if (!!e.data) {
            error = e.data;
        }

        if (error.translationKey) {
            return { translationKey: error.translationKey };
        } else {
            return { message: error.message };
        }
    } else {
        error = e;
        return { message: error };
    }
};

export const numberToMoneyFormat = (value, decimal = 2) => {
    const number = parseInt(value);
    return number.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
};

export const internationalMobilePrefix = (mobileNumber) => {
    if (mobileNumber.substring(0, 2) === "09") {
        return "886" + mobileNumber.substr(1);    
    } else {
        return mobileNumber;
    }
};

export const errorKeyNavigateSystemErrorPage = (errorKey) => {
    if (errorKey) {
        const matchingObj = constants.errorKeyToNavigateSystemErrorPage.find(obj => obj.key === errorKey);
        
        if (matchingObj) {
            navigate(constants.ROUTES.SYSTEM_ERROR);
        }
    }
};

export const getCurrentTimestamp = () => {
    return moment().format(constants.TIMESTAMP_API_MOMENT_FORMAT);
};

export const durationInSecondsToMmSs = (seconds) => {
    return Math.floor(seconds / 60) + ':' + ('0' + Math.floor(seconds % 60)).slice(-2);
};

export const getGenderByNationlId = (nationlId) => {
    const nationalIdGenderPrefix = nationlId?.charAt(1);
    if (nationalIdGenderPrefix === "2") {
        return "女";
    } else {
        return "男";
    }
};